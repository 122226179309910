import { useTranslation } from 'next-i18next';
import { IMainWidgetProps } from '@components/_new_components/Pages/main/MainWidget/MainWidget.type';
import { ACON_MAIN_SLIDE_AREA, ACON_MAIN_SLIDE_AREA_ENUM } from '@components/_new_components/Pages/main/MainWidget/MainWidget.constants';
import { MainWidgetWrapper } from '@components/_new_components/Pages/main/MainWidget/MainWidget.styles';
import ProductCardSlider from '@components/_new_components/Pages/main/ProductCardSlider/ProductCardSlider';
import {
  Language_Code,
  Ordered_Products_Input_Condition_Order_State,
  Ordered_Products_Input_Sort_Criterion,
  Ordered_Products_Input_Sort_Key,
  useOrderedProductsQuery,
} from '../../../../../src/generated/graphql';
import { LANGUAGE_CODE } from '../../../../../src/constants';
import { useMainProductCardSlideQuery } from './MainWidget.hook';

export default function MainWidget({ currentDateString, dailyDateString }: IMainWidgetProps) {
  const { t, i18n } = useTranslation();
  const commonProductsQueryVariables = {
    language: i18n.language.toUpperCase() as Language_Code,
    pagination: { page: 1, limit: 20 },
  };
  // 일간 BEST 상품
  const {
    data: yesterdayBestQueryData,
    loading: yesterdayBestQueryLoading,
    error: yesterdayBestQueryError,
    refetch: yesterdayBestQueryRefetch,
  } = useOrderedProductsQuery({
    variables: {
      ...commonProductsQueryVariables,
      sort: {
        key: Ordered_Products_Input_Sort_Key.OrderCount,
        criterion: Ordered_Products_Input_Sort_Criterion.Desc,
      },
      condition: {
        since: dailyDateString,
        until: currentDateString,
        orderStates: [Ordered_Products_Input_Condition_Order_State.Confirmed, Ordered_Products_Input_Condition_Order_State.Settled],
        isMatureContent: false,
        isOnSale: true,
        salePriceAbove: 1,
      },
    },
  });

  // 월단위 베스트 상품
  const {
    data: monthlyBestQueryData,
    loading: monthlyBestQueryLoading,
    error: monthlyBestQueryError,
    refetch: monthlyBestQueryRefetch,
  } = useOrderedProductsQuery({
    variables: {
      ...commonProductsQueryVariables,
      sort: {
        key: Ordered_Products_Input_Sort_Key.OrderCount,
        criterion: Ordered_Products_Input_Sort_Criterion.Desc,
      },
      condition: {
        // 30일 전부터 현재까지
        since: new Date(new Date(currentDateString).getTime() - 1000 * 60 * 60 * 24 * 30).toISOString(),
        until: currentDateString,
        orderStates: [Ordered_Products_Input_Condition_Order_State.Confirmed, Ordered_Products_Input_Condition_Order_State.Settled],
        isMatureContent: false,
        isOnSale: true,
        salePriceAbove: 1,
      },
    },
  });

  // 몰별 전용 수동구좌
  const areaQueries = [];

  Object.entries(ACON_MAIN_SLIDE_AREA).forEach((_, index: number) => {
    const areaType = ACON_MAIN_SLIDE_AREA[index][i18n.language as LANGUAGE_CODE];
    if (areaType === ACON_MAIN_SLIDE_AREA_ENUM.DAILY_BEST) {
      areaQueries.push({ data: yesterdayBestQueryData, loading: yesterdayBestQueryLoading, error: yesterdayBestQueryError, refetch: yesterdayBestQueryRefetch });
    } else if (areaType === ACON_MAIN_SLIDE_AREA_ENUM.MONTHLY_BEST) {
      areaQueries.push({ data: monthlyBestQueryData, loading: monthlyBestQueryLoading, error: monthlyBestQueryError, refetch: monthlyBestQueryRefetch });
    } else {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const queryResult = useMainProductCardSlideQuery(index, i18n.language as LANGUAGE_CODE);
      areaQueries.push(queryResult);
    }
  });

  return (
    <MainWidgetWrapper>
      {areaQueries.map((query, index) => {
        const areaType = ACON_MAIN_SLIDE_AREA[index][i18n.language as LANGUAGE_CODE];
        const productCardData = { title: null, products: [], clickMixpanelActionName: null, viewMoreLink: null };

        if (areaType === ACON_MAIN_SLIDE_AREA_ENUM.DAILY_BEST) {
          productCardData.title = t('main:dailyBest.title');
          productCardData.products = query.data?.orderedProducts?.data || [];
          productCardData.clickMixpanelActionName = 'main daily best more';
          productCardData.viewMoreLink = '/best';
        } else if (areaType === ACON_MAIN_SLIDE_AREA_ENUM.MONTHLY_BEST) {
          productCardData.title = t('main:totalBest.title');
          productCardData.products = query.data?.orderedProducts?.data || [];
          productCardData.clickMixpanelActionName = 'main monthly best more';
          productCardData.viewMoreLink = '/best?tab=monthly';
        } else {
          productCardData.title = query.data?.displayAssetWithItems?.displayInfo?.title || '';
          productCardData.products = query.data?.displayAssetWithItems?.assets?.data || [];
          if (areaType === ACON_MAIN_SLIDE_AREA_ENUM.RECOMMENDED) {
            productCardData.viewMoreLink = '/new';
            productCardData.clickMixpanelActionName = 'main new more';
          } else if (areaType === ACON_MAIN_SLIDE_AREA_ENUM.BRAND_WEEK) {
            productCardData.clickMixpanelActionName = 'main brand week more';
            if (productCardData.products.length > 0) {
              productCardData.viewMoreLink = productCardData.products[0]?.brandInfo?.code ? `/brand/${productCardData.products[0]?.brandInfo?.code}` : null;
            }
          } else if (areaType === ACON_MAIN_SLIDE_AREA_ENUM.ACON_ONLY) {
            productCardData.viewMoreLink = '/acon-only';
            productCardData.clickMixpanelActionName = 'main acon-only more';
          } else if (
            // 원래 반짝할인은 areaType이 ACON_MAIN_SLIDE_AREA_ENUM.FLASH_SALE이어야 하지만 Retool 쪽에서 구좌의 순서를 바꾸는 과정에서
            // 다른 areaType에 반짝할인을 넣은 상홥입니다. 따라서 해당하는 다른 구좌의 areaType에 따라 반짝할인을 넣어주도록 합니다.
            (i18n.language === LANGUAGE_CODE.KO && areaType === ACON_MAIN_SLIDE_AREA_ENUM.MKT_RECOMMENDED) ||
            (i18n.language === LANGUAGE_CODE.EN && areaType === ACON_MAIN_SLIDE_AREA_ENUM.FLASH_DEALS) ||
            (i18n.language === LANGUAGE_CODE.JP && areaType === ACON_MAIN_SLIDE_AREA_ENUM.MONOCHROME)
          ) {
            productCardData.viewMoreLink = '/category/431';
            productCardData.clickMixpanelActionName = 'main flash sale more';
          }
        }

        return (
          <ProductCardSlider
            key={`product_card_slider_${areaType}_${index}`}
            isLoading={query?.loading}
            title={productCardData.title}
            products={productCardData.products}
            viewMoreLink={productCardData.viewMoreLink}
            clickMixpanelActionName={productCardData.clickMixpanelActionName}
            handleError={{
              isError: Boolean(query?.error),
              refetch: query?.refetch,
            }}
          />
        );
      })}
    </MainWidgetWrapper>
  );
}
